// extracted by mini-css-extract-plugin
export var btn = "Loverpool-module--btn--Twv7G";
export var container = "Loverpool-module--container--alii3";
export var fans = "Loverpool-module--fans--dj7B-";
export var leftBlock = "Loverpool-module--leftBlock--lfPRY";
export var liverpool = "Loverpool-module--liverpool--9mGc1";
export var person = "Loverpool-module--person--qHR70";
export var personText = "Loverpool-module--personText--LVWUc";
export var slider = "Loverpool-module--slider--pBsiu";
export var sliderL = "Loverpool-module--sliderL--2HHdS";
export var sliderM = "Loverpool-module--sliderM--NxcW3";
export var subtitle = "Loverpool-module--subtitle--Teu90";
export var title = "Loverpool-module--title--Zvz9P";
export var wrapp = "Loverpool-module--wrapp--OTUes";