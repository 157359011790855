import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './Loverpool.module.scss'
import Button from '../common/Button'
import personIcon from '../../images/liverpul/Group 191306.svg'
import { Link } from 'gatsby'

const Loverpool = () => {
  return (
    <div className={styles.wrapp}>
      <section className={styles.liverpool}>
        <div className={styles.leftBlock}>
          <div className={styles.container}>
            <div className={styles.title}>
              LIVERPOOL FC <span className={styles.fans}>FANS</span> METAVERSE
            </div>
            <div className={styles.subtitle}>
              Join the cutting-edge LFC fans community, win collectible NFTs and real world prizes,
              socialize with mates and have fun supporting your favourite team!
            </div>
            <div className={styles.sliderM}>
              <StaticImage
                placeholder='blurred'
                src='../../images/liverpul/sliderM.png'
                alt='slider'
              />
            </div>
            <Link to='/aboutNFT' className={styles.btn}>
              <Button>ABOUT NFT CARDS</Button>
            </Link>
          </div>
        </div>
        <div className={styles.slider}>
          <div className={styles.sliderL}>
            <StaticImage
              placeholder='blurred'
              src='../../images/liverpul/sliderL.png'
              alt='slider'
            />
          </div>
        </div>
      </section>
      <a href='https://donate.thedigital.gov.ua/' target='_blank' className={styles.person}>
        <img src={personIcon} alt='person' />
        <span className={styles.personText}>support ukraine</span>
      </a>
    </div>
  )
}

export default Loverpool
