// extracted by mini-css-extract-plugin
export var blockText = "ProjectTeam-module--blockText--0eFyH";
export var collumn = "ProjectTeam-module--collumn--xd2NB";
export var container = "ProjectTeam-module--container--4M3yu";
export var img = "ProjectTeam-module--img--Ova0d";
export var projectTeam = "ProjectTeam-module--projectTeam--I60VL";
export var row = "ProjectTeam-module--row--5Sfq2";
export var span = "ProjectTeam-module--span--xLsJM";
export var subtitle = "ProjectTeam-module--subtitle--t5jzP";
export var team = "ProjectTeam-module--team--Qxi17";
export var title = "ProjectTeam-module--title--cGyV8";