import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from '../common/Button'
import * as styles from './ProjectTeam.module.scss'

const ProjectTeam = () => {
  return (
    <section className={styles.projectTeam}>
      <div className={styles.container}>
        <div className={styles.title}>
          Project <span className={styles.team}>team</span>
        </div>
        <div className={styles.subtitle}>
          This project was created by LFC supporters, the same as you, and inspired by emerging
          technologies, such as blockchain. So, it is made for LFC fans by LFC fans and fully owned
          and controlled by the supporters community.
        </div>
        <div className={styles.row}>
          <div className={styles.collumn}>
            <StaticImage
              className={styles.img}
              width={267}
              height={363}
              src='../../images/projectTeam/person_01.png'
              alt='team person'
            />
            <div className={styles.blockText}>
              ALEX, <span className={styles.span}>CEO</span>
            </div>
          </div>
          <div className={styles.collumn}>
            <StaticImage
              className={styles.img}
              width={267}
              height={363}
              src='../../images/projectTeam/person_02.png'
              alt='team person'
            />
            <div className={styles.blockText}>
              JOHN, <span className={styles.span}>COO</span>
            </div>
          </div>
          <div className={styles.collumn}>
            <StaticImage
              className={styles.img}
              width={267}
              height={363}
              src='../../images/projectTeam/person_03.png'
              alt='team person'
            />
            <div className={styles.blockText}>
              Andrew, <span className={styles.span}>CTO</span>
            </div>
          </div>
          <div className={styles.collumn}>
            <StaticImage
              className={styles.img}
              width={267}
              height={363}
              src='../../images/projectTeam/person_04.png'
              alt='team person'
            />
            <div className={styles.blockText}>
              LIA, <span className={styles.span}>CDO</span>
            </div>
          </div>
        </div>
        <Link to='/about?show=1'>
          <Button justifyContent='center'>More about project</Button>
        </Link>
      </div>
    </section>
  )
}

export default ProjectTeam
