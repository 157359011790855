import React from 'react';
import Button from '../common/Button';
import * as styles from './WantToGet.module.scss';

const WantToGet = () => {
  return (
    <section className={styles.wantToGet}>
      <div className={styles.container}>
        <div className={styles.title}>
          Want to get <span className={styles.free}>FREE membership NFT </span>and join the Reds
          Club?
        </div>
        <div className={styles.subtitle}>
          Fill the application form now and get a chance to mint your personal free membership NFT.
          Limited offer, so hurry up!
        </div>
        <a href='https://forms.gle/DXxUsNJ3E5m74hYv8' className={styles.btn}>
          <Button justifyContent='center'>Apply now</Button>
        </a>
      </div>
    </section>
  );
};

export default WantToGet;
