import * as React from "react";
import Layout from "../component/Layout";
import Competitions from "../component/Competitions";
import { Helmet } from "react-helmet-async";
import Loverpool from "../component/Loverpool";
import Membership from "../component/Membership";
import ProjectTeam from "../component/ProjectTeam";

import WantToGet from "../component/WantToGet/WantToGet";
import NftAwards from "../component/NftAwards";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Reds Club</title>
        <meta property='og:title' content='Reds Club' />
        <meta
          name='description'
          content='LIVERPOOL FC FANS METAVERSE. Join the cutting-edge LFC fans community, win collectible NFTs and real world prizes, socialize with mates and have fun supporting your favourite team!'
        />
        <meta
          property='og:description'
          content='LIVERPOOL FC FANS METAVERSE. Join the cutting-edge LFC fans community, win collectible NFTs and real world prizes, socialize with mates and have fun supporting your favourite team!'
        />
      </Helmet>
      <main>
        <Loverpool />
        <WantToGet />
        <Membership />
        <Competitions />
        <NftAwards />
        <ProjectTeam />
      </main>
    </Layout>
  );
};

export default IndexPage;
