import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from '../common/Button'
import * as styles from './NftAwards.module.scss'

const NftAwards = () => {
  return (
    <div className={styles.NftAwards}>
      <div className={styles.container}>
        <div className={styles.leftBlock}>
          <div className={styles.title}>
            <span className={styles.NFT}>NFT awardS</span> for the best LFC players of the season
          </div>
          <div className={styles.subTitle}>
            Best players will be chosen and awarded by voting of the members of the Reds Club
            community
          </div>

          <StaticImage className={styles.img} src='../../images/nftAwards/card.png' alt='photo' />

          <a href='https://forms.gle/DXxUsNJ3E5m74hYv8' className={styles.btn}>
            <Button>Join the community</Button>
          </a>
        </div>
      </div>
      {/* <div className={styles.imgWrapp}>
        <StaticImage className={styles.img} src='../../images/nftAwards/card.png' alt='photo' />
      </div> */}
    </div>
  )
}

export default NftAwards
