import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../../lib/useDemenstion'
import Button from '../common/Button'
import * as styles from './Competitions.module.scss'

const Competitions = () => {
  // const { height, width, isSmall } = useWindowDimensions()
  // console.log(width, 'width')

  return (
    <section className={styles.competitions}>
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.title}>
            <span className={styles.predict}> Predict</span> LFC stats, become reds club oracle,
            <span className={styles.win}> win </span> collectible NFT!
          </div>
          <div className={styles.imgS}>
            <StaticImage
              placeholder='blurred'
              // className={styles.imgS}
              // width={327}
              // height={417}
              src='../../images/personalCard/table.png'
              alt='image table'
            />
          </div>
          <div className={styles.link}>
            <a href='https://forms.gle/DXxUsNJ3E5m74hYv8' target='_blank'>
              <Button>Apply to join</Button>
            </a>
          </div>
        </div>
        <div className={styles.imgL}>
          <StaticImage
            placeholder='blurred'
            // width={327}
            // height={417}
            src='../../images/personalCard/table3x.png'
            alt='image table'
          />
        </div>
      </div>
    </section>
  )
}

export default Competitions
