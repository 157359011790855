import React from 'react'
import Button from '../common/Button'
import * as styles from './Membership.module.scss'

import icon1 from '../../images/membarship/1.svg'
import icon2 from '../../images/membarship/2.svg'
import icon3 from '../../images/membarship/3.svg'
import icon4 from '../../images/membarship/4.svg'
import icon5 from '../../images/membarship/5.svg'

const Membership = () => {
  const ref = React.useRef()
  const onScreen = useOnScreen(ref)
  const isOne = React.useRef(false)
  const imageA = [styles.img]
  const imageB = [styles.img]
  const imageC = [styles.img]
  const titleA = [styles.title]
  const titleB = [styles.title]
  const titleC = [styles.title]

  if (onScreen && !isOne.current) {
    // isOne.current = true
    imageA.push(styles.showA)
    imageB.push(styles.showB)
    imageC.push(styles.showC)
    titleA.push(styles.showA)
    titleB.push(styles.showB)
    titleC.push(styles.showC)
  }

  return (
    <section className={styles.membership}>
      <div className={styles.container}>
        <div className={styles.titleMain}>
          <span className={styles.why}>Why</span> to get NFT{' '}
          <span className={styles.membershipSpan}>membership</span>{' '}
          <span className={styles.cardSpan}>card?</span>
        </div>
        <div className={styles.row} ref={ref}>
          <div className={styles.item}>
            {onScreen}
            <img className={imageA.join(' ')} src={icon1} alt='hang' />
            <div className={titleA.join(' ')}>
              <p>Get access to private</p>
              <p>Discord chat</p>
            </div>
          </div>
          <div className={styles.item}>
            <img className={imageB.join(' ')} src={icon2} alt='hang' />
            <div className={titleB.join(' ')}>
              <p>Take part in collectible</p> <p>NFT drawings</p>
            </div>
          </div>
          <div className={styles.item}>
            <img className={imageC.join(' ')} src={icon3} alt='hang' />
            <div className={titleC.join(' ')}>
              <p>Play in prediction competitions</p> <p>with other Reds</p>
            </div>
          </div>
          <div className={styles.item}>
            <img className={imageC.join(' ')} src={icon4} alt='hang' />
            <div className={titleC.join(' ')}>
              <p> Vote for the best Liverpool </p>
              <p>players to award them</p>
            </div>
          </div>
          <div className={styles.item}>
            <img className={imageC.join(' ')} src={icon5} alt='hang' />
            <div className={titleC.join(' ')}>
              <p>Take part in the Reds Club</p> governance with DAO
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Membership

function useOnScreen(ref: React.MutableRefObject<any>) {
  const [isIntersecting, setIntersecting] = React.useState(false)
  const isBrowser = typeof window !== 'undefined'
  if (!isBrowser) return
  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

  React.useEffect(() => {
    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}
